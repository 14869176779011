<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-field>
                                <label for="role">Danh mục cha</label>
                                <md-select v-model="entity.parentId" name="parentId">
                                    <md-option value="0">Danh mục cha</md-option>
                                    <md-option v-for="item in parents" :key="item.id" :value="item.id">{{item.categoryName}}</md-option>
                                </md-select>
                            </md-field>
                            <md-field :class="{'md-invalid': submitted && $v.entity.categoryName.$error }">
                                <label for="categoryName">Tên danh mục</label>
                                <md-input name="categoryName" v-model="entity.categoryName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.categoryName.required">Vui lòng nhập tên danh mục</span>
                            </md-field>
                            <md-field>
                                <label for="sort">Số thứ tự</label>
                                <md-input name="sort" v-model="entity.sort"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
    </md-dialog>
</template>
<script>
    import categoryHelperService from '../../../api/categoryHelperService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật danh mục hướng dẫn sử dụng'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               showDialog: false,
               entity: { id: 0, parentId: 0, categoryName: '', sort: 1 },
               saveAndCreate: false,
               parents: []
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            open(id){
                this.getParents();
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới danh mục hướng dẫn sử dụng';
                    this.entity = { id: 0, parentId: 0, categoryName: '', sort: 1 };
                }
                else {
                    this.title = 'Cập nhật danh mục hướng dẫn sử dụng';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                categoryHelperService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close');
                        if(!this.saveAndCreate){
                            this.showDialog = false;
                        }
                        else{
                            this.getParents();
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                categoryHelperService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('close');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                categoryHelperService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getParents(){
                this.setLoading(true);
                categoryHelperService.getAll(0).then((response) => {
                    if(response.statusCode == 200){
                        this.parents = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/category-helper');
            }
        },
        validations: {
            entity: {
                parentId: { required },
                categoryName: { required },
                sort: { required },
            }
        }
    })

</script>


<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 700px;
        height: 370px;
        max-width: 700px;
    }
    .md-scrollbar {
        height: 370px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>